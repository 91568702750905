import { UserRound } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "src/components/ui/button";
import { MuiInput } from "src/components/ui/mui-input";
import { ScrollArea } from "src/components/ui/scroll-area";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "src/components/ui/sheet";
import { cn } from "src/lib/utils";
import { Order, useUserStore } from "src/zustand/userStore";
import { useNavigate } from "react-router-dom";
import { AutreForm } from "./autre-form";
import {
  Form,
  FormControl,
  FormField,
  FormMessage,
} from "src/components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { InformationsSchema } from "src/schemas/Informations";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { InformationsAction } from "src/actions/informations";

interface Props {
  children: React.ReactNode;
}

export const CartSheet = ({ children }: Props) => {
  const store = useUserStore();

  const [isPending, setIsPending] = useState(false);

  const [error, setError] = useState("");

  const names = Array.from(
    new Set(
      store.orders.map((order) => `${order.user.prenom} ${order.user.nom}`)
    )
  );

  const onAvatarClick = (order: Order) => {
    setError("");
    store.setSelectedOrder(order);
    const user = order.user;
    form.setValue("email", user.email);
    form.setValue("mobile", user.mobile);

    Object.keys(user).forEach((key) => {
      form.setValue(
        key as keyof Order["user"],
        user[key as keyof Order["user"]]
      );
    });
  };

  const newUserBtnRef = useRef<React.ElementRef<"button">>(null);

  const existingUserBtn = useRef<React.ElementRef<"button">>(null);

  const navigate = useNavigate();

  const onSubmit = () => {
    setError("");
    const calculateAge = (dateOfBirth: string) => {
      const [day, month, year] = dateOfBirth.split("/").map(Number);
      const birthDate = new Date(year, month - 1, day);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    };

    if (!store.selectedOrder) {
      setError("Veuillez sélectionner un utilisateur");
      return;
    }

    if (store.selectedOrder === "autre") {
      newUserBtnRef.current?.click();
    } else if (store.selectedOrder) {
      if (store.selectedOrder.user.dateOfBirth) {
        const age = calculateAge(store.selectedOrder.user.dateOfBirth);
        if (age < 18) {
          setError("Le titulaire de la carte bancaire doit être majeur.");
          return;
        }
      }

      store.setPaymentInfo({
        ...store.selectedOrder,
        products: store.orders.map((order) => order.product),
      });
      existingUserBtn.current?.click();
    }
  };

  const existingUserFormSubmit = (
    values: z.infer<typeof InformationsSchema>
  ) => {
    setIsPending(true);
    axios.get("https://api.ipify.org/?format=json").then((response) => {
      const ip = response.data.ip; // Adjust depending on API response structure
      const userAgent = navigator.userAgent; // Adjust depending on API response structure
      InformationsAction({
        values,
        ip,
        userAgent,
      }).finally(() => {
        setIsPending(false);
        navigate("/app/paiement");
      });
    });
  };

  const form = useForm<z.infer<typeof InformationsSchema>>({
    resolver: zodResolver(InformationsSchema),
    defaultValues: {},
  });

  useEffect(() => {
    store.setSelectedOrder(undefined);
  }, []);

  useEffect(() => {
    if (store.selectedOrder && store.selectedOrder !== "autre") {
      const user = store.selectedOrder.user;
      form.setValue("email", user.email);
      form.setValue("mobile", user.mobile);

      Object.keys(user).forEach((key) => {
        form.setValue(
          key as keyof Order["user"],
          user[key as keyof Order["user"]]
        );
      });
    }
  }, [store.selectedOrder, form]);

  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="z-[999999] p-0 w-[100dvw] !max-w-[500px] border-0">
        <header className="bg-[#0c131f] h-[4.5rem] p-4 flex items-center w-full">
          <div className="flex items-center w-full justify-between">
            <h1 className="text-xl text-white font-bold">
              Qui passe la commande ?
            </h1>
            <SheetClose>
              <h3 className="text-[#8de8fe] padding-[calc(0.9375rem)] flex items-center justify-center text-sm cursor-pointer">
                Fermer
              </h3>
            </SheetClose>
          </div>
        </header>
        <div className="p-5 xl:p-10 h-[90dvh] xl:h-[94dvh] flex flex-col justify-between">
          <div>
            <p className="text-sm text-muted-foreground">
              *champs obligatoires
            </p>
            <div className="py-4">
              <p className="mb-3 px-4 font-bold">Qui passe la commande ?</p>
              <div className="flex flex-wrap gap-3 mb-4 pt-1 px-3">
                {names.map((name) => {
                  const order = store.orders.find(
                    (order) => `${order.user.prenom} ${order.user.nom}` === name
                  );
                  const firstNameInitial = order!.user.prenom[0];
                  const lastNameInitial = order!.user.nom[0];

                  return (
                    <div
                      className="flex flex-col items-center cursor-pointer"
                      onClick={() => {
                        if (order) {
                          onAvatarClick(order);
                        }
                      }}
                    >
                      <label>
                        <input type="radio" name="avatar" className="hidden" />
                      </label>
                      <div
                        className={cn(
                          "relative size-[3.5rem]",
                          // @ts-ignore
                          store.selectedOrder?.id === order?.id &&
                            "border-2 border-black rounded-full"
                        )}
                      >
                        <span className="m-0 font-bold flex items-center rounded-[50%] justify-center size-full text-black bg-[#f2970f] uppercase">
                          {firstNameInitial}
                          {lastNameInitial}
                        </span>
                      </div>
                      <p className="text-sm pt-[2px] max-w-[150px] w-full overflow-hidden truncate text-center">
                        {name}
                      </p>
                    </div>
                  );
                })}
                <div
                  className="flex flex-col items-center cursor-pointer"
                  onClick={() => {
                    store.setSelectedOrder("autre");
                  }}
                >
                  <label>
                    <input type="radio" name="avatar" className="hidden" />
                  </label>
                  <div
                    className={cn(
                      "relative size-[3.5rem]",
                      store.selectedOrder === "autre" &&
                        "border-2 border-black rounded-full"
                    )}
                  >
                    <span className="m-0 font-bold flex items-center rounded-[50%] justify-center size-full text-black bg-[#f2970f] uppercase">
                      <UserRound />
                    </span>
                  </div>
                  <p className="text-sm pt-[2px] w-full overflow-hidden truncate text-center">
                    Autre
                  </p>
                </div>
              </div>
            </div>
            <ScrollArea className="h-[50dvh]">
              {store.selectedOrder && store.selectedOrder !== "autre" ? (
                <Form {...form}>
                  <form
                    className="flex flex-col gap-3 py-5"
                    onSubmit={form.handleSubmit(existingUserFormSubmit)}
                  >
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <>
                          <FormControl>
                            <MuiInput
                              label="Adresse e-mail (exemple@mail.fr)*"
                              className="flex-grow w-full"
                              {...field}
                              onChange={(e) => {
                                if (
                                  store.selectedOrder &&
                                  store.selectedOrder !== "autre"
                                ) {
                                  store.setSelectedOrder({
                                    ...store.selectedOrder,
                                    user: {
                                      ...store.selectedOrder.user,
                                      email: e.target.value,
                                    },
                                  });
                                }
                                field.onChange(e.target.value);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="mobile"
                      render={({ field }) => (
                        <>
                          <FormControl>
                            <MuiInput
                              label="Numéro de mobile*"
                              className="flex-grow w-full"
                              {...field}
                              onChange={(e) => {
                                let input = e.target.value.replace(/\D+/g, ""); // Remove non-digit characters
                                if (input.length > 10) {
                                  input = input.substring(0, 10); // Limit input to 10 digits
                                }

                                // Add space after every 2 digits
                                const formattedInput = input
                                  .split("") // Convert string to array of characters
                                  .reduce((acc, digit, idx) => {
                                    if (idx !== 0 && idx % 2 === 0) {
                                      acc += " "; // Add a space before the digit if it's after every 2 digits
                                    }
                                    acc += digit;
                                    return acc;
                                  }, "");

                                if (
                                  store.selectedOrder &&
                                  store.selectedOrder !== "autre"
                                ) {
                                  store.setSelectedOrder({
                                    ...store.selectedOrder,
                                    user: {
                                      ...store.selectedOrder.user,
                                      mobile: formattedInput,
                                    },
                                  });
                                }

                                field.onChange(formattedInput); // Update the field value
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </>
                      )}
                    />
                    <button
                      type="submit"
                      className="hidden"
                      ref={existingUserBtn}
                    ></button>
                  </form>
                </Form>
              ) : (
                store.selectedOrder && (
                  <AutreForm
                    ref={newUserBtnRef}
                    showSubmit={false}
                    onPending={setIsPending}
                  />
                )
              )}
            </ScrollArea>
          </div>
          <div className="flex flex-col items-center">
            {error && <p className="text-destructive">{error}</p>}
            <br />
            {isPending ? (
              <img
                src="/better-lodd.gif"
                alt="loader"
                className="size-[50px] mx-auto"
              />
            ) : (
              <Button onClick={onSubmit} disabled={isPending}>
                Enregistrer
              </Button>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
