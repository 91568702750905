import {
  MerciInfo,
  Order,
  PaymentInfo,
  UserStoreState,
} from "src/zustand/userStore";
import creditCardType from "credit-card-type";
import { calculateProductValidity } from "src/lib/utils";
import { EmailProductCard } from "./email-card";

interface Props {
  store: UserStoreState;
}
export const EmailTemplate = ({ store }: Props) => {
  return (
    <html>
      <head />
      <body cz-shortcut-listen="true">
        <div
          style={{
            boxSizing: "border-box",
            color: "#000",
            fontFamily:
              "'Avenir LT Std 55 Roman', Helvetica, Arial, sans-serif",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "1.3",
            margin: "0",
            minWidth: "100%",
            padding: "0",
            textAlign: "left",
            width: "100% !important",
          }}
        >
          <table
            style={{
              background: "#fff",
              borderCollapse: "collapse",
              borderSpacing: "0",
              color: "#000",
              fontFamily:
                "'Avenir LT Std 55 Roman', Helvetica, Arial, sans-serif",
              fontSize: "14px",
              fontWeight: "400",
              height: "100%",
              lineHeight: "1.3",
              margin: "0",
              padding: "0",
              textAlign: "left",
              verticalAlign: "top",
              width: "100%",
            }}
          >
            <tbody>
              <tr
                style={{
                  padding: "0",
                  textAlign: "left",
                  verticalAlign: "top",
                }}
              >
                <td
                  style={{
                    borderCollapse: "collapse",
                    color: "#000",
                    fontFamily:
                      "'Avenir LT Std 55 Roman', Helvetica, Arial,                  sans-serif",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "1.3",
                    margin: "0",
                    padding: "0",
                    textAlign: "left",
                    verticalAlign: "top",
                    wordWrap: "break-word",
                  }}
                  valign="top"
                >
                  <center
                    style={{
                      minWidth: "700px",
                      width: "100%",
                    }}
                  >
                    <table
                      role="presentation"
                      style={{
                        background: "#fff",
                        borderCollapse: "collapse",
                        borderSpacing: "0",
                        float: "none",
                        margin: "0 auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "0",
                        textAlign: "center",
                        verticalAlign: "top",
                        width: "700px",
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            padding: "0",
                            textAlign: "left",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              borderCollapse: "collapse",
                              color: "#000",
                              fontFamily:
                                "'Avenir LT Std 55 Roman', Helvetica,                            Arial, sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "1.3",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                              verticalAlign: "top",
                              wordWrap: "break-word",
                            }}
                          >
                            <table
                              role="presentation"
                              style={{
                                backgroundColor: "#0c131f",
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                padding: "0",
                                textAlign: "left",
                                verticalAlign: "top",
                              }}
                              width="100%"
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <img
                                      alt="SNCF CONNECT"
                                      height="60"
                                      src="https://medias.cdn.vsct.fr/odr/prod/mail-confirmation/img/common/header/logo-sncf-connect.png"
                                      style={{
                                        clear: "both",
                                        display: "block",
                                        marginLeft: "25px",
                                        maxWidth: "100%",
                                        outline: "0",
                                        textDecoration: "none",
                                        width: "auto",
                                      }}
                                      width="121"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              role="presentation"
                              style={{
                                backgroundColor: "#128096",
                                backgroundRepeat: "no-repeat",
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                color: "#fff",
                                minHeight: "150px",
                                padding: "0",
                                textAlign: "left",
                                verticalAlign: "top",
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <table
                                      role="presentation"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            height="42.5px"
                                            style={{
                                              borderCollapse: "collapse",
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman', Helvetica, Arial, sans-serif",
                                              fontSize: "42.5px",
                                              fontWeight: "400",
                                              lineHeight: "42.5px",
                                              margin: "0",
                                              padding: "0",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              wordWrap: "break-word",
                                            }}
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      border={0}
                                      cellPadding="0"
                                      cellSpacing="0"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                      }}
                                      width="100%"
                                    >
                                      <tbody />
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            style={{
                                              borderCollapse: "collapse",
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              height: "40px",
                                              lineHeight: "1.3",
                                              margin: "0",
                                              padding: "0",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              width: "700px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <h1
                                              style={{
                                                color: "#fff",
                                                fontFamily:
                                                  "AchemineBold, Arial,                                              Helvetica, sans-serif !important",
                                                fontSize: "40px",
                                                fontStyle: "normal !important",
                                                fontVariant:
                                                  "normal !important",
                                                fontWeight: "400",
                                                lineHeight: "1",
                                                margin: "0",
                                                marginBottom: "4px",
                                                padding: "0",
                                                textAlign: "center",
                                                wordWrap: "normal",
                                              }}
                                            >
                                              Merci
                                            </h1>
                                          </td>
                                        </tr>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            style={{
                                              borderCollapse: "collapse",
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              height: "40px",
                                              lineHeight: "1.3",
                                              margin: "0",
                                              padding: "0",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              width: "700px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "#fff",
                                                fontFamily:
                                                  "'Avenir LT Std 55 Roman',                                              Helvetica, Arial, sans-serif",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "1.3",
                                                margin: "0",
                                                marginBottom: "0",
                                                padding: "0",
                                                textAlign: "center",
                                              }}
                                            >
                                              Et bon voyage !
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      role="presentation"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            height="42.5px"
                                            style={{
                                              borderCollapse: "collapse",
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "42.5px",
                                              fontWeight: "400",
                                              lineHeight: "42.5px",
                                              margin: "0",
                                              padding: "0",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              wordWrap: "break-word",
                                            }}
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      role="presentation"
                      style={{
                        background: "#fff",
                        borderCollapse: "collapse",
                        borderSpacing: "0",
                        float: "none",
                        margin: "0 auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "0",
                        textAlign: "center",
                        verticalAlign: "top",
                        width: "700px",
                      }}
                    >
                      <tbody>
                        <tr
                          style={{
                            padding: "0",
                            textAlign: "left",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              borderCollapse: "collapse",
                              color: "#000",
                              fontFamily:
                                "'Avenir LT Std 55 Roman', Helvetica,                            Arial, sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "1.3",
                              margin: "0",
                              padding: "0",
                              textAlign: "left",
                              verticalAlign: "top",
                              wordWrap: "break-word",
                            }}
                          >
                            <table
                              role="presentation"
                              style={{
                                background: "#fff",
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                float: "none",
                                margin: "0 auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "0",
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "700px",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  />
                                </tr>
                              </tbody>
                            </table>
                            <table
                              role="presentation"
                              style={{
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                padding: "0",
                                textAlign: "left",
                                verticalAlign: "top",
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    height="30px"
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#0e0e0e",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "26px",
                                      fontWeight: "400",
                                      lineHeight: "30px",
                                      margin: "auto",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingTop: "16px",
                                        paddingBottom: "16px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Vos récapitulatifs de commande
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br />

                            <table
                              role="presentation"
                              style={{
                                background: "#fff",
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                float: "none",
                                margin: "0 auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "0",
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "700px",
                              }}
                            >
                              <tbody>
                                <tr style={{ padding: "0" }}>
                                  <td
                                    style={{
                                      padding: "30px",
                                      paddingTop: "0px",
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "20px",
                                    }}
                                  >
                                    {store.orders.map((order) => {
                                      return (
                                        <EmailProductCard
                                          product={order.product}
                                          startDate={new Date()}
                                          endDate={
                                            new Date(order.productValidity)
                                          }
                                          referenceString={
                                            store.merciInfo?.referenceId ||
                                            "ABXLA"
                                          }
                                          prenom={order.user.prenom}
                                          nom={order.user.nom}
                                        />
                                      );
                                    })}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <table
                                      className="ox-7df3eb6376-row ox-7df3eb6376-myTrip ox-7df3eb6376-float-center"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        float: "none",
                                        margin: "0 auto",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        maxWidth: "630px",
                                        padding: "0",
                                        position: "relative",
                                        textAlign: "center",
                                        verticalAlign: "top",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            className="ox-7df3eb6376-small-12 ox-7df3eb6376-large-12 ox-7df3eb6376-columns ox-7df3eb6376-first ox-7df3eb6376-last"
                                            style={{
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "1.3",
                                              margin: "0 auto",
                                              paddingBottom: "0",
                                              paddingLeft: "20px",
                                              paddingRight: "20px",
                                              textAlign: "left",
                                              verticalAlign: "middle",
                                              width: "680px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <table
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "middle",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <table
                                                      style={{
                                                        borderCollapse:
                                                          "collapse",
                                                        borderSpacing: "0",
                                                        padding: "0",
                                                        textAlign: "left",
                                                        verticalAlign: "top",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr
                                                          style={{
                                                            padding: "0",
                                                            textAlign: "left",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              color: "#000",
                                                              fontFamily:
                                                                "'Avenir LT Std 55 Roman',                                                            Helvetica, Arial,                                                            sans-serif",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                              lineHeight: "1.3",
                                                              margin: "0",
                                                              padding: "0",
                                                              textAlign: "left",
                                                              verticalAlign:
                                                                "middle",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            <table
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse",
                                                                borderSpacing:
                                                                  "0",
                                                                padding: "0",
                                                                textAlign:
                                                                  "left",
                                                                verticalAlign:
                                                                  "top",
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr
                                                                  style={{
                                                                    padding:
                                                                      "0",
                                                                    textAlign:
                                                                      "left",
                                                                    verticalAlign:
                                                                      "top",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      color:
                                                                        "#000",
                                                                      fontFamily:
                                                                        "'Avenir LT Std 55 Roman',                                                                    Helvetica,                                                                    Arial,                                                                    sans-serif",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight:
                                                                        "1.3",
                                                                      margin:
                                                                        "0",
                                                                      padding:
                                                                        "0",
                                                                      textAlign:
                                                                        "left",
                                                                      verticalAlign:
                                                                        "middle",
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                    width="62"
                                                                  >
                                                                    <img
                                                                      alt=""
                                                                      className="ox-7df3eb6376-inline"
                                                                      src="https://medias.cdn.vsct.fr/odr/prod/mail-confirmation/img/confirmation/delivery-mode/commercial-card/ivtsTKD.png"
                                                                      style={{
                                                                        clear:
                                                                          "both",
                                                                        display:
                                                                          "inline",
                                                                        maxWidth:
                                                                          "100%",
                                                                        textDecoration:
                                                                          "none",
                                                                        width:
                                                                          "auto",
                                                                      }}
                                                                    />
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      color:
                                                                        "#000",
                                                                      fontFamily:
                                                                        "'Avenir LT Std 55 Roman',                                                                    Helvetica,                                                                    Arial,                                                                    sans-serif",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight:
                                                                        "1.3",
                                                                      margin:
                                                                        "0",
                                                                      padding:
                                                                        "0",
                                                                      textAlign:
                                                                        "left",
                                                                      verticalAlign:
                                                                        "middle",
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="ox-7df3eb6376-myTrip__delivery-mode__title"
                                                                      style={{
                                                                        fontFamily:
                                                                          "'Avenir LT Std 55 Roman',                                                                      Helvetica,                                                                      Arial,                                                                      sans-serif !important",
                                                                        fontWeight:
                                                                          "700",
                                                                        marginBottom:
                                                                          "2px",
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        Carte
                                                                        digitalisée
                                                                        (PDF)
                                                                      </span>
                                                                    </div>
                                                                    <div className="ox-7df3eb6376-myTrip__mentions">
                                                                      <span>
                                                                        Vous
                                                                        recevrez
                                                                        un
                                                                        second
                                                                        e-mail
                                                                        distinct
                                                                        de cette
                                                                        confirmation
                                                                        de
                                                                        commande
                                                                        dans les
                                                                        48
                                                                        heures,
                                                                        contenant
                                                                        tous les
                                                                        détails
                                                                        de votre
                                                                        commande
                                                                        et les
                                                                        informations
                                                                        pour
                                                                        récupérer
                                                                        votre
                                                                        carte.
                                                                        Vous
                                                                        serez
                                                                        débité à
                                                                        ce
                                                                        moment-là.
                                                                      </span>
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td
                                                    className="ox-7df3eb6376-expander"
                                                    style={{
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "middle",
                                                      visibility: "hidden",
                                                      width: "0",
                                                      wordWrap: "break-word",
                                                    }}
                                                  />
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      className="ox-7df3eb6376-spacer"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            height="30px"
                                            style={{
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "30px",
                                              fontWeight: "400",
                                              lineHeight: "30px",
                                              margin: "0",
                                              padding: "0",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              wordWrap: "break-word",
                                            }}
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <div
                                      className="ox-7df3eb6376-transaction__info"
                                      style={{
                                        padding: "0",
                                      }}
                                    >
                                      <div
                                        className="ox-7df3eb6376-transaction__title"
                                        style={{
                                          color: "#000",
                                          fontSize: "26px",
                                          marginBottom: "33px",
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <div>
                                          <span className="ox-7df3eb6376-transaction__title--first">
                                            Récapitulatif de votre paiement
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="ox-7df3eb6376-transaction__subtitle"
                                        style={{
                                          marginBottom: "25px",
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <div>
                                          <p
                                            style={{
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "1.3",
                                              margin: "0",
                                              marginBottom: "0",
                                              padding: "0",
                                            }}
                                          >
                                            <span>
                                              {`Transaction de paiement avec la carte                                          bancaire ${
                                                creditCardType(
                                                  store.merciInfo?.card
                                                    .cardNumber!
                                                )[0].niceType
                                              } n°                                          #######${store.merciInfo?.card.cardNumber.slice(
                                                -4
                                              )}`}
                                            </span>
                                          </p>
                                        </div>
                                        <p
                                          style={{
                                            color: "#000",
                                            fontFamily:
                                              "'Avenir LT Std 55 Roman',                                          Helvetica, Arial, sans-serif",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "1.3",
                                            margin: "0",
                                            marginBottom: "0",
                                            padding: "0",
                                          }}
                                        >
                                          <span>
                                            Débit - Vente à distance sécurisée
                                          </span>
                                        </p>
                                        <div
                                          style={{
                                            paddingTop: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "24px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              maxWidth: "400px",
                                              marginLeft: "auto",
                                              marginRight: "auto",
                                            }}
                                          >
                                            <div>Total commande </div>
                                            <div>
                                              {store.paymentInfo?.products
                                                .reduce(
                                                  (acc, product) =>
                                                    acc +
                                                    parseFloat(
                                                      product.price.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    ),
                                                  0
                                                )
                                                .toFixed(2)
                                                .replace(".", ",")}{" "}
                                              €
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <br />
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    className="ox-7df3eb6376-expander"
                                    style={{
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      visibility: "hidden",
                                      width: "0",
                                      wordWrap: "break-word",
                                    }}
                                  />
                                </tr>
                              </tbody>
                            </table>
                            <div
                              style={{
                                margin: "0 auto",
                                maxWidth: "630px",
                              }}
                            >
                              <table
                                style={{
                                  borderCollapse: "collapse",
                                  borderSpacing: "0",
                                  padding: "0",
                                  textAlign: "left",
                                  verticalAlign: "top",
                                }}
                              >
                                <tbody>
                                  <tr
                                    style={{
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderCollapse: "collapse",
                                        color: "#000",
                                        fontFamily:
                                          "'Avenir LT Std 55 Roman',                                      Helvetica, Arial, sans-serif",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "1.3",
                                        margin: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        width: "50%",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <div
                                        style={{
                                          borderRight: "1px solid #ecedeb",
                                          color: "#000",
                                          fontSize: "26px",
                                          padding: "25px 30px 25px 0",
                                          textAlign: "right",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <span>Avez-vous l'appli </span>
                                        <strong>
                                          <span>SNCF</span> Connect ?
                                        </strong>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        borderCollapse: "collapse",
                                        color: "#000",
                                        fontFamily:
                                          "'Avenir LT Std 55 Roman',                                      Helvetica, Arial, sans-serif",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "1.3",
                                        margin: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        width: "50%",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <div
                                        style={{
                                          padding: "0 0 0 30px",
                                        }}
                                      >
                                        <table
                                          style={{
                                            borderCollapse: "collapse",
                                            borderSpacing: "0",
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                            width: "100%",
                                          }}
                                        >
                                          <tbody>
                                            <tr
                                              style={{
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              <td
                                                colSpan={2}
                                                style={{
                                                  borderCollapse: "collapse",
                                                  color: "#000",
                                                  fontFamily:
                                                    "'Avenir LT Std 55 Roman',                                                Helvetica, Arial, sans-serif",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "20px",
                                                  margin: "0",
                                                  padding: "0",
                                                  paddingBottom: "20px",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <strong>
                                                  Retrouvez votre E-billet,
                                                  recevez le quai de départ de
                                                  votre train, échangez ou
                                                  annulez votre billet en
                                                  quelques instants.
                                                </strong>
                                              </td>
                                            </tr>
                                            <tr
                                              style={{
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  color: "#000",
                                                  fontFamily:
                                                    "'Avenir LT Std 55 Roman',                                                Helvetica, Arial, sans-serif",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "1.3",
                                                  margin: "0",
                                                  padding: "0",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <a
                                                  href="https://play.google.com/store/apps/details?id=com.vsct.vsc.mobile.horaireetresa.android"
                                                  style={{
                                                    color: "#ea5330",
                                                    fontFamily:
                                                      "'Avenir LT Std 55 Roman',                                                  Helvetica, Arial, sans-serif",
                                                    fontWeight: "400",
                                                    lineHeight: "1.3",
                                                    margin: "0",
                                                    padding: "0",
                                                    textAlign: "left",
                                                    textDecoration: "underline",
                                                  }}
                                                  target="_blank"
                                                >
                                                  <img
                                                    alt="Google Play"
                                                    data-bit="iit"
                                                    src="https://medias.cdn.vsct.fr/odr/prod/mail-confirmation/img/common/pushApp/pushGooglePlay.png"
                                                    style={{
                                                      border: "none",
                                                      clear: "both",
                                                      display: "block",
                                                      height: "auto",
                                                      marginLeft: "auto",
                                                      marginRight: "10px",
                                                      maxWidth: "100%",
                                                      outline: "0",
                                                      textDecoration: "none",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </a>
                                              </td>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  color: "#000",
                                                  fontFamily:
                                                    "'Avenir LT Std 55 Roman',                                                Helvetica, Arial, sans-serif",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "1.3",
                                                  margin: "0",
                                                  padding: "0",
                                                  textAlign: "left",
                                                  verticalAlign: "top",
                                                  wordWrap: "break-word",
                                                }}
                                              >
                                                <a
                                                  href="https://apps.apple.com/fr/app/sncf-connect-trains-trajets/id343889987"
                                                  style={{
                                                    color: "#ea5330",
                                                    fontFamily:
                                                      "'Avenir LT Std 55 Roman',                                                  Helvetica, Arial, sans-serif",
                                                    fontWeight: "400",
                                                    lineHeight: "1.3",
                                                    margin: "0",
                                                    padding: "0",
                                                    textAlign: "left",
                                                    textDecoration: "underline",
                                                  }}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    alt="Apple Store"
                                                    data-bit="iit"
                                                    src="https://medias.cdn.vsct.fr/odr/prod/mail-confirmation/img/common/pushApp/pushAppStore.png"
                                                    style={{
                                                      border: "none",
                                                      clear: "both",
                                                      display: "block",
                                                      height: "auto",
                                                      marginLeft: "10px",
                                                      marginRight: "auto",
                                                      maxWidth: "100%",
                                                      outline: "0",
                                                      textDecoration: "none",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <table
                              role="presentation"
                              style={{
                                background: "#fff",
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                float: "none",
                                margin: "0 auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "600px",
                                padding: "0",
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "700px",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <table
                                      role="presentation"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        display: "table",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            style={{
                                              borderCollapse: "collapse",
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "1.3",
                                              margin: "0 auto",
                                              padding: "0",
                                              paddingBottom: "0",
                                              paddingLeft: "20px",
                                              paddingRight: "20px",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              width: "680px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <table
                                              role="presentation"
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      wordWrap: "break-word",
                                                    }}
                                                  />
                                                  <td
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0",
                                                      padding: "0 !important",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      width: "0",
                                                      wordWrap: "break-word",
                                                    }}
                                                  />
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              role="presentation"
                              style={{
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                padding: "0",
                                textAlign: "left",
                                verticalAlign: "top",
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    height="40px"
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "40px",
                                      fontWeight: "400",
                                      lineHeight: "40px",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              role="presentation"
                              style={{
                                background: "#fff",
                                backgroundColor: "#0c131f",
                                borderCollapse: "collapse",
                                borderSpacing: "0",
                                float: "none",
                                margin: "0 auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "700px",
                                padding: "0",
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "100% !important",
                              }}
                            >
                              <tbody>
                                <tr
                                  style={{
                                    padding: "0",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderCollapse: "collapse",
                                      color: "#000",
                                      fontFamily:
                                        "'Avenir LT Std 55 Roman',                                    Helvetica, Arial, sans-serif",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "1.3",
                                      margin: "0",
                                      padding: "0",
                                      textAlign: "left",
                                      verticalAlign: "top",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <table
                                      role="presentation"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0",
                                        padding: "0",
                                        textAlign: "left",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <tbody>
                                        <tr
                                          style={{
                                            padding: "0",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          <td
                                            style={{
                                              borderCollapse: "collapse",
                                              color: "#000",
                                              fontFamily:
                                                "'Avenir LT Std 55 Roman',                                            Helvetica, Arial, sans-serif",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "1.3",
                                              margin: "0",
                                              padding: "0",
                                              textAlign: "left",
                                              verticalAlign: "top",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            <table
                                              role="presentation"
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    height="30px"
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "30px",
                                                      fontWeight: "400",
                                                      lineHeight: "30px",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      wordWrap: "break-word",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              role="presentation"
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                display: "table",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#fff",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "11px",
                                                      fontWeight: "400",
                                                      lineHeight: "15px",
                                                      margin: "0 auto",
                                                      padding: "0 !important",
                                                      paddingBottom: "0",
                                                      paddingLeft: "20px",
                                                      paddingRight: "20px",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      width: "100% !important",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <table
                                                      role="presentation"
                                                      style={{
                                                        borderCollapse:
                                                          "collapse",
                                                        borderSpacing: "0",
                                                        padding: "0",
                                                        textAlign: "left",
                                                        verticalAlign: "top",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr
                                                          style={{
                                                            padding: "0",
                                                            textAlign: "left",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              borderCollapse:
                                                                "collapse",
                                                              color: "#000",
                                                              fontFamily:
                                                                "'Avenir LT Std 55 Roman',                                                            Helvetica, Arial,                                                            sans-serif",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                              lineHeight: "1.3",
                                                              margin: "0",
                                                              padding: "0",
                                                              textAlign: "left",
                                                              verticalAlign:
                                                                "top",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                color: "#000",
                                                                fontFamily:
                                                                  "'Avenir LT Std 55 Roman',                                                              Helvetica, Arial,                                                              sans-serif",
                                                                fontSize:
                                                                  "12px",
                                                                fontWeight:
                                                                  "400",
                                                                lineHeight:
                                                                  "20px",
                                                                margin: "0",
                                                                marginBottom:
                                                                  "0",
                                                                padding:
                                                                  "0 20px !important",
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              <a
                                                                href="https://www.sncf-connect.com/statics/cgv/tarifs-voyageurs-fr-fr.pdf"
                                                                style={{
                                                                  color: "#fff",
                                                                  fontFamily:
                                                                    "'Avenir LT Std 55 Roman',                                                                Helvetica, Arial,                                                                sans-serif",
                                                                  fontWeight:
                                                                    "400",
                                                                  lineHeight:
                                                                    "1.3",
                                                                  margin: "0",
                                                                  padding: "0",
                                                                  textAlign:
                                                                    "left",
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                                target="_blank"
                                                                title="Conditions Générales de Vente SNCF"
                                                              >
                                                                <span>
                                                                  Conditions
                                                                  Générales de
                                                                  Vente
                                                                  <span>
                                                                    SNCF
                                                                  </span>
                                                                </span>
                                                              </a>
                                                              •
                                                              <a
                                                                href="https://www.sncf-connect.com/statics/cgv/cgv-fr-fr.pdf"
                                                                style={{
                                                                  color: "#fff",
                                                                  fontFamily:
                                                                    "'Avenir LT Std 55 Roman',                                                                Helvetica, Arial,                                                                sans-serif",
                                                                  fontWeight:
                                                                    "400",
                                                                  lineHeight:
                                                                    "1.3",
                                                                  margin: "0",
                                                                  padding: "0",
                                                                  textAlign:
                                                                    "left",
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                                target="_blank"
                                                                title="Conditions Générales de Vente et d'Utilisation de OUI.sncf"
                                                              >
                                                                <span>
                                                                  Conditions
                                                                  Générales de
                                                                  Vente et
                                                                  d'Utilisation
                                                                  du site
                                                                  <span>
                                                                    SNCF
                                                                  </span>
                                                                  -Connect.com
                                                                </span>
                                                              </a>
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              borderCollapse:
                                                                "collapse",
                                                              color: "#000",
                                                              fontFamily:
                                                                "'Avenir LT Std 55 Roman',                                                            Helvetica, Arial,                                                            sans-serif",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                              lineHeight: "1.3",
                                                              margin: "0",
                                                              padding:
                                                                "0 !important",
                                                              textAlign: "left",
                                                              verticalAlign:
                                                                "top",
                                                              width: "0",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          />
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              role="presentation"
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    height="25px"
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "25px",
                                                      fontWeight: "400",
                                                      lineHeight: "25px",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      wordWrap: "break-word",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              role="presentation"
                                              style={{
                                                backgroundColor: "#0c131f",
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                display: "table",
                                                padding: "0 20px !important",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100% !important",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0 auto",
                                                      padding: "0 !important",
                                                      paddingBottom: "0",
                                                      paddingLeft: "20px",
                                                      paddingRight: "10px",
                                                      textAlign: "left",
                                                      verticalAlign: "middle",
                                                      width: "96.66667px",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <table
                                                      role="presentation"
                                                      style={{
                                                        borderCollapse:
                                                          "collapse",
                                                        borderSpacing: "0",
                                                        padding: "0",
                                                        textAlign: "left",
                                                        verticalAlign: "top",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr
                                                          style={{
                                                            padding: "0",
                                                            textAlign: "left",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              borderCollapse:
                                                                "collapse",
                                                              color: "#000",
                                                              fontFamily:
                                                                "'Avenir LT Std 55 Roman',                                                            Helvetica, Arial,                                                            sans-serif",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                              lineHeight: "1.3",
                                                              margin: "0",
                                                              padding: "0",
                                                              textAlign: "left",
                                                              verticalAlign:
                                                                "top",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                paddingLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              <img
                                                                alt=""
                                                                data-bit="iit"
                                                                src="https://medias.cdn.vsct.fr/odr/prod/mail-confirmation/img/common/footer/footer-sncf-connect.png"
                                                                style={{
                                                                  clear: "both",
                                                                  display:
                                                                    "block",
                                                                  maxWidth:
                                                                    "100%",
                                                                  outline: "0",
                                                                  textDecoration:
                                                                    "none",
                                                                  width: "auto",
                                                                }}
                                                              />
                                                            </div>
                                                            <table
                                                              role="presentation"
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse",
                                                                borderSpacing:
                                                                  "0",
                                                                padding: "0",
                                                                textAlign:
                                                                  "left",
                                                                verticalAlign:
                                                                  "top",
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr
                                                                  style={{
                                                                    padding:
                                                                      "0",
                                                                    textAlign:
                                                                      "left",
                                                                    verticalAlign:
                                                                      "top",
                                                                  }}
                                                                >
                                                                  <td
                                                                    height="20px"
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      color:
                                                                        "#000",
                                                                      fontFamily:
                                                                        "'Avenir LT Std 55 Roman',                                                                    Helvetica,                                                                    Arial,                                                                    sans-serif",
                                                                      fontSize:
                                                                        "20px",
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight:
                                                                        "20px",
                                                                      margin:
                                                                        "0",
                                                                      padding:
                                                                        "0",
                                                                      textAlign:
                                                                        "left",
                                                                      verticalAlign:
                                                                        "top",
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  ></td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0 auto",
                                                      padding: "0 !important",
                                                      paddingBottom: "0",
                                                      paddingLeft: "10px",
                                                      paddingRight: "20px",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      width: "563.33333px",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <table
                                                      role="presentation"
                                                      style={{
                                                        borderCollapse:
                                                          "collapse",
                                                        borderSpacing: "0",
                                                        padding: "0",
                                                        textAlign: "left",
                                                        verticalAlign: "top",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr
                                                          style={{
                                                            padding: "0",
                                                            textAlign: "left",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              borderCollapse:
                                                                "collapse",
                                                              color: "#000",
                                                              fontFamily:
                                                                "'Avenir LT Std 55 Roman',                                                            Helvetica, Arial,                                                            sans-serif",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                              lineHeight: "1.3",
                                                              margin: "0",
                                                              padding: "0",
                                                              textAlign: "left",
                                                              verticalAlign:
                                                                "top",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                color: "#fff",
                                                                fontFamily:
                                                                  "'Avenir LT Std 55 Roman',                                                              Helvetica, Arial,                                                              sans-serif",
                                                                fontSize:
                                                                  "11px",
                                                                fontWeight:
                                                                  "400",
                                                                lineHeight:
                                                                  "15px",
                                                                margin: "0",
                                                                marginBottom:
                                                                  "0",
                                                                padding:
                                                                  "0 20px !important",
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              <a
                                                                href="https://www.sncf-connect.com"
                                                                style={{
                                                                  color: "#fff",
                                                                  fontFamily:
                                                                    "'Avenir LT Std 55 Roman',                                                                Helvetica, Arial,                                                                sans-serif",
                                                                  fontWeight:
                                                                    "400",
                                                                  lineHeight:
                                                                    "1.3",
                                                                  margin: "0",
                                                                  padding: "0",
                                                                  textAlign:
                                                                    "left",
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                                target="_blank"
                                                                title="SNCF Connect"
                                                              >
                                                                <span>
                                                                  SNCF
                                                                </span>
                                                                Connect
                                                              </a>
                                                              <span>
                                                                - 2 place de la
                                                                Défense, CNIT 1,
                                                                BP 440, 92053
                                                                Paris La Défense
                                                                Cedex
                                                              </span>
                                                              <span>
                                                                - RCS Nanterre B
                                                                431 810 621 -
                                                                S.A.S au capital
                                                                de 10.672.000 €
                                                                - Licence
                                                                d'agence de
                                                                voyages n° LI
                                                                092 01 0002
                                                              </span>
                                                              <span>
                                                                76431810621
                                                              </span>
                                                              <span>
                                                                - - Garantie
                                                                financière :
                                                                APST - R.C : AXA
                                                              </span>
                                                            </p>
                                                            <table
                                                              role="presentation"
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse",
                                                                borderSpacing:
                                                                  "0",
                                                                padding: "0",
                                                                textAlign:
                                                                  "left",
                                                                verticalAlign:
                                                                  "top",
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr
                                                                  style={{
                                                                    padding:
                                                                      "0",
                                                                    textAlign:
                                                                      "left",
                                                                    verticalAlign:
                                                                      "top",
                                                                  }}
                                                                >
                                                                  <td
                                                                    height="20px"
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      color:
                                                                        "#000",
                                                                      fontFamily:
                                                                        "'Avenir LT Std 55 Roman',                                                                    Helvetica,                                                                    Arial,                                                                    sans-serif",
                                                                      fontSize:
                                                                        "20px",
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight:
                                                                        "20px",
                                                                      margin:
                                                                        "0",
                                                                      padding:
                                                                        "0",
                                                                      textAlign:
                                                                        "left",
                                                                      verticalAlign:
                                                                        "top",
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  ></td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              role="presentation"
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    height="5px"
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "5px",
                                                      fontWeight: "400",
                                                      lineHeight: "5px",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      wordWrap: "break-word",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              role="presentation"
                                              style={{
                                                background: "#fff",
                                                backgroundColor: "#0c131f",
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                margin: "0 auto",
                                                padding: "0",
                                                textAlign: "inherit",
                                                verticalAlign: "top",
                                                width: "100% !important",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "1.3",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <table
                                                      role="presentation"
                                                      style={{
                                                        backgroundColor:
                                                          "#0c131f",
                                                        borderCollapse:
                                                          "collapse",
                                                        borderSpacing: "0",
                                                        float: "none",
                                                        margin: "0 auto",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        padding: "0",
                                                        textAlign: "center",
                                                        verticalAlign: "top",
                                                        width:
                                                          "100% !important",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr
                                                          style={{
                                                            padding: "0",
                                                            textAlign: "left",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              borderCollapse:
                                                                "collapse",
                                                              color: "#000",
                                                              fontFamily:
                                                                "'Avenir LT Std 55 Roman',                                                            Helvetica, Arial,                                                            sans-serif",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                              lineHeight: "1.3",
                                                              margin: "0 auto",
                                                              padding:
                                                                "0 !important",
                                                              paddingBottom:
                                                                "0",
                                                              paddingLeft:
                                                                "20px",
                                                              paddingRight:
                                                                "20px",
                                                              textAlign: "left",
                                                              verticalAlign:
                                                                "top",
                                                              width: "680px",
                                                              wordWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            <table
                                                              role="presentation"
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse",
                                                                borderSpacing:
                                                                  "0",
                                                                padding: "0",
                                                                textAlign:
                                                                  "left",
                                                                verticalAlign:
                                                                  "top",
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr
                                                                  style={{
                                                                    padding:
                                                                      "0",
                                                                    textAlign:
                                                                      "left",
                                                                    verticalAlign:
                                                                      "top",
                                                                  }}
                                                                >
                                                                  <td
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      color:
                                                                        "#000",
                                                                      fontFamily:
                                                                        "'Avenir LT Std 55 Roman',                                                                    Helvetica,                                                                    Arial,                                                                    sans-serif",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight:
                                                                        "1.3",
                                                                      margin:
                                                                        "0",
                                                                      padding:
                                                                        "0",
                                                                      textAlign:
                                                                        "left",
                                                                      verticalAlign:
                                                                        "top",
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  >
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#fff",
                                                                        fontFamily:
                                                                          "'Avenir LT Std 55 Roman',                                                                      Helvetica,                                                                      Arial,                                                                      sans-serif",
                                                                        fontSize:
                                                                          "11px",
                                                                        fontWeight:
                                                                          "400",
                                                                        lineHeight:
                                                                          "15px",
                                                                        margin:
                                                                          "0",
                                                                        marginBottom:
                                                                          "12px",
                                                                        padding:
                                                                          "0                                                                      20px",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      Nous vous
                                                                      conseillons
                                                                      d'imprimer
                                                                      cette page
                                                                      afin de
                                                                      conserver
                                                                      les
                                                                      différentes
                                                                      références
                                                                      de votre
                                                                      commande.
                                                                      Ce mail ne
                                                                      peut en
                                                                      aucun cas
                                                                      remplacer
                                                                      un titre
                                                                      de
                                                                      transport.
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#fff",
                                                                        fontFamily:
                                                                          "'Avenir LT Std 55 Roman',                                                                      Helvetica,                                                                      Arial,                                                                      sans-serif",
                                                                        fontSize:
                                                                          "11px",
                                                                        fontWeight:
                                                                          "400",
                                                                        lineHeight:
                                                                          "15px",
                                                                        margin:
                                                                          "0",
                                                                        marginBottom:
                                                                          "12px",
                                                                        padding:
                                                                          "0                                                                      20px",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      Merci de
                                                                      ne pas
                                                                      répondre
                                                                      directement
                                                                      à ce mail
                                                                      de
                                                                      confirmation,
                                                                      votre
                                                                      message ne
                                                                      serait pas
                                                                      traité.
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          "#fff",
                                                                        fontFamily:
                                                                          "'Avenir LT Std 55 Roman',                                                                      Helvetica,                                                                      Arial,                                                                      sans-serif",
                                                                        fontSize:
                                                                          "11px",
                                                                        fontWeight:
                                                                          "400",
                                                                        lineHeight:
                                                                          "15px",
                                                                        margin:
                                                                          "0",
                                                                        marginBottom:
                                                                          "0",
                                                                        padding:
                                                                          "0                                                                      20px",
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      <span>
                                                                        Pour
                                                                        vous
                                                                        assurer
                                                                        de
                                                                        recevoir
                                                                        nos
                                                                        e-mails
                                                                        de
                                                                        confirmation
                                                                        d'achat,
                                                                        nous
                                                                        vous
                                                                        recommandons
                                                                        d'ajouter
                                                                        l'adresse{" "}
                                                                      </span>
                                                                      <a
                                                                        href="/"
                                                                        style={{
                                                                          color:
                                                                            "#fff",
                                                                          fontFamily:
                                                                            "'Avenir LT Std 55 Roman',                                                                        Helvetica,                                                                        Arial,                                                                        sans-serif",
                                                                          fontWeight:
                                                                            "400",
                                                                          lineHeight:
                                                                            "1.3",
                                                                          margin:
                                                                            "0",
                                                                          padding:
                                                                            "0",
                                                                          textAlign:
                                                                            "left",
                                                                          textDecoration:
                                                                            "none",
                                                                        }}
                                                                      >
                                                                        noreply@connect.
                                                                        <span>
                                                                          sncf
                                                                        </span>
                                                                      </a>
                                                                      <span>
                                                                        à votre
                                                                        carnet
                                                                        d'adresses.
                                                                      </span>
                                                                    </p>
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      color:
                                                                        "#000",
                                                                      fontFamily:
                                                                        "'Avenir LT Std 55 Roman',                                                                    Helvetica,                                                                    Arial,                                                                    sans-serif",
                                                                      fontSize:
                                                                        "14px",
                                                                      fontWeight:
                                                                        "400",
                                                                      lineHeight:
                                                                        "1.3",
                                                                      margin:
                                                                        "0",
                                                                      padding:
                                                                        "0 !important",
                                                                      textAlign:
                                                                        "left",
                                                                      verticalAlign:
                                                                        "top",
                                                                      width:
                                                                        "0",
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  />
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table
                                              role="presentation"
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                padding: "0",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr
                                                  style={{
                                                    padding: "0",
                                                    textAlign: "left",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  <td
                                                    height="25px"
                                                    style={{
                                                      borderCollapse:
                                                        "collapse",
                                                      color: "#000",
                                                      fontFamily:
                                                        "'Avenir LT Std 55 Roman',                                                    Helvetica, Arial, sans-serif",
                                                      fontSize: "25px",
                                                      fontWeight: "400",
                                                      lineHeight: "25px",
                                                      margin: "0",
                                                      padding: "0",
                                                      textAlign: "left",
                                                      verticalAlign: "top",
                                                      wordWrap: "break-word",
                                                    }}
                                                  ></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </body>
    </html>
  );
};
