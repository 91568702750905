import { DesktopDescriptionBanner } from "../ui/desktop-description-banner";
import { MobileDescriptionBanner } from "../ui/mobile-description-banner";

interface DescriptionBannerProps {
  desktopImage: string;
  mobileImage: string;
  cardTitle: string;
  cardDescription: string;
  price: string;
  buttonLabel: string;
  href: string;
  badge?: string;
  badgeColor?: string;
}
export const DescriptionBanner = ({
  desktopImage,
  mobileImage,
  cardTitle,
  cardDescription,
  price,
  buttonLabel,
  href,
  badge,
  badgeColor,
}: DescriptionBannerProps) => {
  return (
    <>
      <DesktopDescriptionBanner
        image={desktopImage}
        cardTitle={cardTitle}
        cardDescription={cardDescription}
        price={price}
        badge={badge}
        badgeColor={badgeColor}
        buttonLabel={buttonLabel}
        href={href}
      />
      <MobileDescriptionBanner
        image={mobileImage}
        cardTitle={cardTitle}
        cardDescription={cardDescription}
        price={price}
        badgeColor={badgeColor}
        badge={badge}
        buttonLabel={buttonLabel}
        href={href}
      />
    </>
  );
};
