import creditCardType from "credit-card-type";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "src/components/ui/sheet";
import { MerciInfo, useUserStore } from "src/zustand/userStore";
import { getCardSvg } from "./svg";

interface Props {
  children: React.ReactNode;
  merciInfo: MerciInfo;
}

export const TransactionSheet = ({ children, merciInfo }: Props) => {
  const store = useUserStore();

  const last4digits = merciInfo.card.cardNumber.slice(-4);

  const cardType = creditCardType(merciInfo.card.cardNumber);

  function formatDate(date: Date) {
    const daysOfWeek = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const monthsOfYear = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${dayOfWeek}. ${dayOfMonth} ${month} ${hours}:${minutes}`;
  }

  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="z-[999999] p-0 w-[100dvw] !max-w-[500px] border-0">
        <header className="bg-[#0c131f] h-[4.5rem] p-4 flex items-center w-full">
          <div className="flex items-center w-full justify-between">
            <h1 className="text-xl text-white font-bold">Transaction</h1>
            <SheetClose>
              <h3 className="text-[#8de8fe] padding-[calc(0.9375rem)] flex items-center justify-center text-sm cursor-pointer">
                Fermer
              </h3>
            </SheetClose>
          </div>
        </header>
        <section className="flex flex-col gap-3 bg-white">
          <ul className="bg-gray-200 flex flex-col py-10 px-8">
            <li>
              Transaction de paiement la carte bancaire {cardType[0].niceType}
            </li>
            <li>n&#176;#######{last4digits}</li>
            <li>Débit - Vente à distance sécurisée</li>
          </ul>

          <ul className="bg-gray-200 flex flex-col py-10 px-8">
            <li>
              <span className="text-muted-foreground">Usage </span>
              <span>Débit</span>
            </li>
            <li>
              <span className="text-muted-foreground">Transaction </span>
              <span>n&#176;{merciInfo.transactionNo}</span>
            </li>
            <li>
              <span className="text-muted-foreground">par </span>
              <span>201519037580036 SNCF EUROPE France</span>
            </li>
            <li>
              <span className="text-muted-foreground">Effectuée le </span>
              <span>
                {formatDate(
                  new Date(store.merciInfo?.paymentDateTime || new Date())
                )}
              </span>
            </li>
            <li>
              <span className="text-muted-foreground">Autorisation </span>
              <span>n&#176;{merciInfo.autorisationNo}</span>
            </li>
            <li>
              <span className="text-muted-foreground">Référence commande </span>
              <span>{store.merciInfo?.referenceId}</span>
            </li>
          </ul>
          <ul className="bg-gray-200 flex flex-col py-10 px-8">
            <p>
              <span className="text-muted-foreground flex-1">
                Commerçant :{" "}
              </span>
              <span>
                SNCF Connect 2 place de la Défense, CNIT 1, BP 440, 92053 Paris
                La Défense Cedex - https://www.sncf- connect.com
              </span>
            </p>
          </ul>
          <ul className="bg-gray-200 flex flex-col py-10 px-8">
            <li className="flex gap-2">
              <div className="size-12 bg-white rounded-md">{getCardSvg(cardType[0].type)}</div>
              <div className="flex flex-col justify-between">
                <div className="text-muted-foreground text-sm">
                  Total commande :{" "}
                </div>
                <div className="font-bold">
                  {store.paymentInfo?.products
                    .reduce(
                      (acc, product) =>
                        acc + parseFloat(product.price.replace(",", ".")),
                      0
                    )
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  €
                </div>
              </div>
            </li>
          </ul>
        </section>
      </SheetContent>
    </Sheet>
  );
};
