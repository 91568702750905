import { UserStoreState } from "src/zustand/userStore";
import { renderToStaticMarkup } from "react-dom/server";
import { EmailTemplate } from "src/components/email-template";
import React from "react";
import axios from "axios";
interface Props {
  store: UserStoreState
}

export const SendEmailAction = async ({
  store
}: Props) => {
  let subject = "";
  if (store.merciInfo && store.merciInfo.products.length > 1) {
    subject = `Vos récapitulatifs de commande`;
  } else {
    subject = ` Votre ${store.merciInfo &&  store.merciInfo.products[0].name}`;
  }

  const element = React.createElement(EmailTemplate, {
    store,
  });

  const emailBody = renderToStaticMarkup(element);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sendemail`,
      {
        email: store.merciInfo?.user.email  ,
        subject,
        html: emailBody,
      }
    );

    if (response.status === 200) {
      console.log("Sent sms successfully", response.data);
    } else {
      console.error("Failed to send sms", response.status, response.data);
    }
  } catch (error) {
    console.error("Error processing sms", error);
  }
};
