import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { OffresFooter } from "../../offres-footer"; 
import { AdulteDesktop } from "./main-desktop";
import { AdulteMobile } from "./main-mobile";
import { ChatbotSection } from "../../chatbot-section";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";

export const CarteAvantageAdulteView = () => {
  return (
    <>
      <Helmet>
        <title>Carte Avantage Adulte - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title="Carte Avantage Adulte" />
      <DescriptionBanner
        mobileImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_360x214/public/media/2022-01/adultes.jpg?itok=CQcTe-2n"
        desktopImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/adultes.jpg?itok=xL8AsXwX"
        badge="Promotion"
        badgeColor="#8de8fe"
        cardTitle="Carte Avantage Adulte"
        cardDescription="27 - 59 ans"
        price="2,45 €"
        buttonLabel="Commander la carte"
        href={`/app/catalogue/pricing/sncf?productId=${PriceIds["carte-avantage-adulte"]}`}
      />
      <AdulteDesktop />
      <AdulteMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
