import { cn } from "src/lib/utils";

type Props = {
  label: string;
  description: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement> ;

export const PaymentInput = ({
  label,
  description,
  className,
  ...props
}: Props) => {
  return (
    <div className={cn("flex flex-col", className)}>
      <label className="text-[#5e6878] font-semibold">{label}</label>
      <input
        {...props}
        className="my-2 text-[#5e6878] font-bold p-[14px] rounded-[8px] border border-[#848b9b] outline-[#127996] w-full"
      />
      <p className="text-[#5e6878] text-sm">{description}</p>
    </div>
  );
};
