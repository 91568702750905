import { OffresMainDesktop } from "src/components/ui/offres-main-desktop";
import { OffresMainMobile } from "src/components/ui/offres-main-mobile";

export interface Offer {
  href: string;
  img: string;
  title: string;
  description: string;
  oldPrice: React.ReactNode;
  newPrice: string;
  planValidity: string;
  badge?: string;
  badgeColor?: string;
}

export const OffresMain = () => {
  const baseOffers: Partial<Offer>[] = [
    {
      href: "/app/catalogue/description/pass-rail",
      title: "Découvrez le Pass Rail",
      description: "Entre 16 et 27 ans pour des voyages en juillet et août",
      badge: "Nouveau",
      badgeColor: "rgb(241, 200, 60)",
      oldPrice: (
        <span className="text-lg text-black">
          2,45 €{" "}
          <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
        </span>
      ),
      newPrice: "2,45 €",
      planValidity: "Mensuel",
    },
    {
      href: "/app/catalogue/description/carte-avantage-jeune",
      title: "Carte Avantage Jeune",
      description: "12 - 27 ans",
      badge: "Promotion",
      badgeColor: "#8de8fe",
      oldPrice: (
        <span className="text-lg text-black">
          2,45 €{" "}
          <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
        </span>
      ),
      newPrice: "2,45 €",
      planValidity: "Annuel",
    },
    {
      href: "/app/catalogue/description/carte-avantage-adulte",
      title: "Carte Avantage Adulte",
      description: "27 - 59 ans",
      badge: "Promotion",
      badgeColor: "#8de8fe",
      oldPrice: (
        <span className="text-lg text-black">
          2,45 €{" "}
          <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
        </span>
      ),
      newPrice: "2,45 €",
      planValidity: "Annuel",
    },
    {
      href: "/app/catalogue/description/carte-avantage-senior",
      title: "Carte Avantage Senior",
      description: "+ 60 ans",
      badge: "Promotion",
      badgeColor: "#8de8fe",
      oldPrice: (
        <span className="text-lg text-black">
          2,45 €{" "}
          <span className="line-through text-xs text-[#5E6878]"> 49 €</span>
        </span>
      ),
      newPrice: "2,45 €",
      planValidity: "Annuel",
    },
    {
      href: "/app/catalogue/description/carte-liberte",
      title: "Carte Liberté",
      badge: "Promotion",
      badgeColor: "#8de8fe",
      description: "La Carte Liberté s'adresse à tous les voyageurs fréquents",
      oldPrice: (
        <span className="text-lg text-black">
          19,95 €{" "}
          <span className="line-through text-xs text-[#5E6878]"> 399 €</span>
        </span>
      ),
      newPrice: "19,95 €",
      planValidity: "Annuel",
    },
  ];

  const mobileImgs = [
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2024-06/tetiere_catalogue.jpg?itok=kmyFUAwt",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/1440x795_jeunes2.jpg?itok=MVeeJqrF",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/adultes.jpg?itok=xL8AsXwX",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/1440x795_cartesseniors.jpg?itok=iWkopDtM",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2024-03/page_produit_grande_tetiere_1440x795.png?itok=BnC2IC1D",
  ];

  const desktopImgs = [
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2024-06/tetiere_catalogue.jpg?itok=kmyFUAwt",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/1440x795_jeunes2.jpg?itok=MVeeJqrF",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/adultes.jpg?itok=xL8AsXwX",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/1440x795_cartesseniors.jpg?itok=iWkopDtM",
    "https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2024-03/page_produit_grande_tetiere_1440x795.png?itok=BnC2IC1D",
  ];

  const mobileOffers = baseOffers.map((offer, index) => ({
    ...offer,
    img: mobileImgs[index],
  }));

  const desktopOffers = baseOffers.map((offer, index) => ({
    ...offer,
    img: desktopImgs[index],
  }));

  return (
    <>
      <OffresMainDesktop offers={desktopOffers as Offer[]} />
      <OffresMainMobile offers={mobileOffers as Offer[]} />
    </>
  );
};
