import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { CartSheet } from "./cart-sheet";

export interface Product {
  [x: string]: any;
  name: string;
  price: string;
}

interface Props {
  products: Product[];
}

export const CartPreview = ({ products }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="xl:max-w-[400px] flex-grow">
      <div className="rounded-t-xl bg-white p-4">
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row justify-center md:justify-start md:items-center gap-1">
            <h2 className="font-bold text-lg xl:text-xl text-nowrap">
              Détail de la commande
            </h2>
            <span className="text-muted-foreground text-sm">
              ({products.length > 1
                ? `${products.length} produits`
                : `${products.length} produit`})
            </span>
          </div>
          <div className="py-2">
            {products.map((product) => (
              <div className="flex flex-col" key={product.name}>
                <div className="flex justify-between">
                  <span className="text-muted-foreground text-base font-semibold">
                    {product.name}
                  </span>
                  <span className="font-bold">{`${product.price}`}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-[2px] bg-white rounded-b-xl p-4">
        <div className="flex justify-between">
          <div className="font-bold text-lg">Total commande</div>
          <div className="font-bold text-xl">
            {products
              .reduce(
                (acc, product) =>
                  acc + parseFloat(product.price.replace(",", ".")),
                0
              )
              .toFixed(2)
              .replace(".", ",")}{" "}
            €
          </div>
        </div>
        <div className="flex flex-col gap-3 pt-4 max-w-[280px] mx-auto">
          <CartSheet>
            <Button>Valider le panier</Button>
          </CartSheet>
          <Button
            variant="outline"
            onClick={() => {
              navigate("/app/catalogue");
            }}
          >
            Ajouter un autre billet
          </Button>
        </div>
      </div>
    </div>
  );
};
