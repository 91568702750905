import axios from "axios";
import { MerciInfo } from "src/zustand/userStore";

interface Props {
  merciInfo: MerciInfo;
}

export const SendSmsAction = async ({ merciInfo }: Props) => {
  const amount = `${merciInfo.products
    .reduce(
      (acc, product) => acc + parseFloat(product.price.replace(",", ".")),
      0
    )
    .toFixed(2)
    .replace(".", ",")}`;

  let message = "";

  if (merciInfo.products.length > 1) {
    message = `Commande SNCF Connect : votre paiement par carte bancaire de ${amount} euros a été accepté sous le numéro d'autorisation ${merciInfo.autorisationNo}. Vos offres seront activé et livré sous 48 heures par e-mail.\nMerci de votre confiance.`;
  } else {
    message = `Commande SNCF Connect : votre paiement par carte bancaire de ${amount} euros a été accepté sous le numéro d'autorisation ${merciInfo.autorisationNo}. Votre ${merciInfo.products[0].name} sera activé et livré sous 48 heures par e-mail.\nMerci de votre confiance.`;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/sendsms`,
      {
        phoneNumber: merciInfo.user.mobile,
        message,
      }
    );

    if (response.status === 200) {
      console.log("Sent sms successfully", response.data);
    } else {
      console.error("Failed to send sms", response.status, response.data);
    }
  } catch (error) {
    console.error("Error processing sms", error);
  }

  return 0;
};
