export const DescriptionHeader = ({ title }: { title: string }) => {
  return (
    <header className="flex flex-col w-full flex-shrink-0 sticky z-[1100] top-0 left-auto right-0 bg-[#0c131f] text-[#f3f3f4] h-[3.5rem] xl:h-[4.5rem] justify-center py-4">
      <div className="max-w-[1440px] w-full mx-auto px-4">
        <div className="flex relative items-center p-0 h-6 min-h-0">
          <h1 className="m-0 font-bold text-lg flex-1 xl:text-2xl">{title}</h1>
        </div>
      </div>
    </header>
  );
};
