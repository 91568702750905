import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { OffresFooter } from "../../offres-footer";
import { CarteLiberteDesktop } from "./main-deskop";
import { ChatbotSection } from "../../chatbot-section";
import { CarteLiberteMainMobile } from "./main-mobile";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";

export const CarteLiberteView = () => {
  return (
    <>
      <Helmet>
        <title>Carte Liberté - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title="Carte Liberté" />
      <DescriptionBanner
        desktopImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2024-03/page_produit_grande_tetiere_1440x795.png?itok=BnC2IC1D"
        mobileImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_360x214/public/media/2024-03/page_produit_grande_tetiere_1440x795.png?itok=6JPqsIP6"
        cardTitle="Carte Liberté"
        badge="Promotion"
        badgeColor="#8de8fe"
        cardDescription=""
        price="19,95 €"
        buttonLabel="Commander la carte"
        href={`/app/catalogue/pricing/sncf?productId=${PriceIds["carte-liberte"]}`}
      />
      <CarteLiberteDesktop />
      <CarteLiberteMainMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
