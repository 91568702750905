import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { OffresFooter } from "../catalogue/offres-footer";
import { ResponsiveChatbot } from "src/components/responsive/reponsive-chatbot";
import { PaymentForm } from "./payment-form";
import { PaymentPreview } from "./payment-preview";
import { useRef } from "react";
import { Misc } from "./misc";
import { Helmet } from "react-helmet";

export const PaiementView = () => {
  const paymentBtnRef = useRef<React.ElementRef<"button">>(null);

  const onClick = () => {
    paymentBtnRef.current?.click();
  };

  return (
    <>
      <Helmet>
        <title>SNCF Connect - Paiement</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title="Paiement" />
      <div className="flex flex-col xl:flex-row max-w-[1300px] mx-auto py-5 px-[10px] gap-3">
        <div className="p-8 bg-white rounded-xl w-full xl:w-[70%]">
          <p className="text-sm mb-4">*champs obligatoires</p>
          <PaymentForm ref={paymentBtnRef} />
        </div>
        <div className="xl:w-[30%]">
          <PaymentPreview onClick={onClick} />
        </div>
      </div>
      <div className="flex flex-col xl:flex-row max-w-[1300px] mx-auto px-[10px] gap-3">
        <div className="w-full xl:w-[70%]">
          <Misc />
        </div>
        <div className="w-[30%]">&nbsp;</div>
      </div>
      <ResponsiveChatbot />
      <OffresFooter />
    </>
  );
};
