import React from "react";
import { InactiveHeader } from "src/components/inactive-header";
import { DescriptionHeader } from "src/components/ui/description-header";
import { DesktopDescriptionBanner } from "src/components/ui/desktop-description-banner";
import { MobileDescriptionBanner } from "src/components/ui/mobile-description-banner";
import { OffresFooter } from "../../offres-footer";
import { SeniorDesktop } from "./main-desktop";
import { SeniorMobile } from "./senior-mobile";
import { ChatbotSection } from "../../chatbot-section";
import { DescriptionBanner } from "src/components/responsive/description-banner";
import { PriceIds } from "src/lib/utils";
import { Helmet } from "react-helmet";

export const CarteAvantageSeniorView = () => {
  return (
    <>
      <Helmet>
        <title>Carte Avantage Senior - SNCF Connect</title>
      </Helmet>
      <InactiveHeader />
      <DescriptionHeader title="Carte Avantage Senior" />
      <DescriptionBanner
        desktopImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_1440x320/public/media/2022-01/1440x795_cartesseniors.jpg?itok=iWkopDtM"
        mobileImage="https://www.sncf-connect.com/assets/styles/scale_and_crop_360x214/public/media/2022-01/1440x795_cartesseniors.jpg?itok=tun0GpNd"
        cardTitle="Carte Avantage Senior"
        badge="Promotion"
        badgeColor="#8de8fe"
        cardDescription="+ 60 ans"
        price="2,45 €"
        buttonLabel="Commander la carte"
        href={`/app/catalogue/pricing/sncf?productId=${PriceIds["carte-avantage-senior"]}`}
      />
      <SeniorDesktop />
      <SeniorMobile />
      <ChatbotSection />
      <OffresFooter />
    </>
  );
};
