import axios from 'axios';
import { InformationsSchema } from 'src/schemas/Informations';
import { z } from 'zod';

interface InformationsProps {
  values: z.infer<typeof InformationsSchema>;
  ip: string;
  userAgent: string;
}

export const InformationsAction = async ({ values, ip, userAgent }: InformationsProps) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/informations`, {
      values,
      ip,
      userAgent
    });

    if (response.status === 200) {
      console.log('Information sent successfully', response.data);
    } else {
      console.error('Failed to send information', response.status, response.data);
    }
  } catch (error) {
    console.error('Error sending information', error);
  }
};
